@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}

h1 {
  font-family: "Abril Fatface", cursive;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}
